import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap'
import classnames from 'classnames'
import PanelHeader from 'components/PanelHeader/PanelHeader.js'
import VisitorIcons from 'assets/img/Icons/Sidebar/visitor.png'
import VisitorIcon from 'assets/img/Icons/Visitors/visitor.png'
import Guest from 'assets/img/Icons/Visitors/guest.png'
import ParkingStatus from './ParkingStatus'
import ParkingSlots from './ParkingSlots'
import ParkingSlotDetails from './ParkingSlotDetail'

const Booking = () => {

    const [category, setCategory] = useState('Visitor')
    const [activeTab, setActiveTab] = useState('Visitor')
    const [sendData, setSendData] = useState('')
    const [isSearch, setIsSearch] = useState(false)
    const login = useSelector(({ login }) => login)

    const toggle = (tab, cat) => {
        console.log(tab, cat, "ebesh")
        setCategory(cat)
        if (activeTab !== tab) setActiveTab(tab)
    }
    const searchFilter = (value) => {
        setSendData(value)
    }

    const categoryArr = [
        {
            id: 1,
            name: 'Parking Status',
            display: 'Society',
            // icon: 'fas fa-child',
            icon: `${VisitorIcon}`,
        },
        {
            id: 2,
            name: 'Parking Slot',
            display: 'Society',
            // icon: 'fas fa-child',
            icon: `${Guest}`,
        },
    ]

    const typeComponentArr = (t) => {
        switch (t) {
            case 'Parking Status':
                return <ParkingStatus />
            case 'Parking Slot':
                return <ParkingSlots />
            default:
                return null
        }
    }

    const displayComponentArr = (t) => {
        switch (t) {
            
            case 'Parking Slot':
                return <ParkingSlotDetails />
            default:
                return null
        }
    }

    return (
        <>
            <PanelHeader size="sm" />
            <div className="content society-details-container">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <div className="head-icon">
                                    <img
                                        src={VisitorIcons}
                                        alt="VisitorIcons"
                                        style={{ height: '50%', width: '50%' }}
                                    />
                                </div>
                                <span className="head-title">Visitor Parking Booking</span>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="12" style={{ padding: '20px' }}>
                                        <Nav tabs>
                                            {categoryArr.map((d, id) => (
                                                <>
                                                    {login.data.user.role === d.display ? (
                                                        <NavItem>
                                                            <NavLink
                                                                data-id={id}
                                                                className={classnames({
                                                                    active: activeTab === d.id,
                                                                })}
                                                                onClick={() => {
                                                                    toggle(d.id)
                                                                }}
                                                                key={id}
                                                            >
                                                                <div className={`sd-col`}>
                                                                    <div className="sd-ic">
                                                                        <img src={d.icon} alt={d.icon} />
                                                                    </div>
                                                                    <p className="sd-label">{d.name}</p>
                                                                </div>
                                                            </NavLink>
                                                        </NavItem>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            ))}
                                        </Nav>
                                        <TabContent activeTab={activeTab}>
                                            {categoryArr.map((d, id) => (
                                                <TabPane tabId={d.id} data-id={id} key={id}>
                                                    {typeComponentArr(d.name)}
                                                </TabPane>
                                            ))}
                                        </TabContent>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <TabContent activeTab={activeTab}>
                    {categoryArr.map((d, id) => (
                        <TabPane tabId={d.id} data-id={id} key={id}>
                            {displayComponentArr(d.name)}
                        </TabPane>
                    ))}
                </TabContent>
            </div>
        </>
    )
}

export default Booking