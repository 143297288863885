import React from 'react'
import axios from './axios'
import {LOGOUT_SUCCESS} from '../redux/types'
export const refreshAccessToken = async (token,store) => {
    if (token) {
        try {
            const response = await axios.post('user/regenerateAuthToken', { authToken: token })
            const { authToken } = response.data
            if (authToken) {
                // sessionStorage.setItem('authToken', authToken)
                return authToken;
            }
            if (response.data.statusCode === 405) return store.dispatch({ type: LOGOUT_SUCCESS })
          
            
        } catch (err) {
            store.dispatch({ type: LOGOUT_SUCCESS })
            console.log(err, "Error in regenerateAuthToken")
        }
    } else {
        return token;
    }
}


export const isExpiredJWTToken = (token) => {
    try {
        if (token && token != 'undefined') {
            const decodedJwt = JSON.parse(atob(token.split(".")[1]));
            if (decodedJwt.exp * 1000 < Date.now()) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }
    catch (err) {
        console.log(err, "IsExpiredToken", token)
    }

}

