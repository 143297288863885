import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Upload, message, Select } from 'antd'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from 'reactstrap'

import Util from 'validations'
import { Spin } from 'antd'

import { getAllSecurityAlert } from '../../redux/actions/getAllSecurityAlertListAction'
import { getGuardList } from 'redux/actions/getGuardListAction'
import {
  resetGetBuildingList,
  getBuildingList,
} from 'redux/actions/getBuildingListAction'
import {
  resetGetUnitFlatList,
  getUnitFlatList,
} from 'redux/actions/getUnitFlatListAction'
import axios from 'config/axios'
import moment from 'moment'

const SearchSecurityAlert = (props) => {
  const [flatNo, setFlatNo] = useState('')
  const [unitId, setUnitId] = useState('')
  const [securityAlert, setSecurityAlert] = useState('')
  const [guardName, setGuardName] = useState('')
  const [guardId, setGuardId] = useState('')
  const [guardnotFound, setGuardNotFound] = useState('')
  const [dateFilter, setDateFilter] = useState('')
  const [bldgName, setBldgName] = useState('')
  const [flatName, setFlatName] = useState('')
  const [buildingId, setBuildingId] = useState('')
  const [searchAreaName, setSearchAreaName] = useState('')
  const [flatid, setflatid] = useState('')
  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [optionsUnit, setOptionsUnit] = useState([''])
  const [areaType, setareaType] = useState('Building')
  const [searchFlat, setSearchFlat] = useState('')

  const login = useSelector(({ login }) => login)
  const guardList = useSelector(({ getGuardList }) => getGuardList)
  const allSecurityAlertList = useSelector(({ getAllSecurityAlertList }) => getAllSecurityAlertList)
  const getFlat = useSelector(({ getUnitFlatList }) => getUnitFlatList)

  const dispatch = useDispatch()
  const getSecurityAlertRequest = () => {
    const getSecurityAlertReq = {
      initiatedBy: "Society",
      societyId: login.data.society.id,
      guardId: guardName === "" ? guardnotFound : guardId,
      unitId: unitId,
      requesttime: dateFilter,
      limit: 5,
    }
    return dispatch(getAllSecurityAlert(getSecurityAlertReq))
  }
  useEffect(() => {
    if (flatName !== '') {
      getFlat.data.activeUnitList.map((obj) =>
        flatName === obj.unit ? setUnitId(obj.id) : setFlatNo(flatName)
      )
    }
  }, [flatName])

  useEffect(() => {
    const req = {
      initiatedBy: "Society",
      societyId: login.data.society.id
    }
    dispatch(getGuardList(req))
  }, [login.data.society.id])

  useEffect(() => {
    if (guardName !== '') {
      guardList.data.activeGuardList.map((d, id) => (
        guardName == d.name ? setGuardId(d.id) : setGuardNotFound(guardName)
      ))
    } else if (guardName === '') {
      setGuardNotFound(guardName)
      setGuardId('')
    }
  }, [guardName, guardId])

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'flatName')
      Util.alphaNumeric(value)
        ? setFlatName(value)
        : setFlatName(flatName)

    if (name === 'securityAlert')
      Util.alphabetsOnly(value)
        ? setSecurityAlert(value)
        : setSecurityAlert(securityAlert)

    if (name === 'guardName')
      Util.alphabetsOnly(value)
        ? setGuardName(value)
        : setGuardName(guardName)

    if (name === 'dateFilter') setDateFilter(value)
  }

  const handleSubmit = (e) => {
    props.setIsSearch()
    e.preventDefault()
    getSecurityAlertRequest()
    const obj = {
      unitId: unitId ? unitId : flatNo,
      guardId: guardId !== "" ? guardId : guardnotFound,
      requesttime: dateFilter
    }
    send(obj)

  }

  console.log(unitId, flatNo, flatid, 'unittt')

  function send(value) {
    props.searchFilter(value)
  }

  // Building Filter

  const { Option } = Select

  function onChangeSearch(value) {
    let val = value.split(',')
    let val1 = val[0]
    let val2 = val[1]
    setBuildingId(val1)
    setBldgName(val2)
    setSearchAreaName('')
    setflatid('')
    setFlatName('')
    setUnitId('')
  }

  function onSearch(value) {
    setSearchAreaName(value)
  }

  function onChangeSearchFlatId(value) {
    console.log(value, "value")
    let flatval = value.split(",")
    let flatval1 = flatval[0]
    let flatval2 = flatval[1]
    setflatid(flatval1)
    setFlatName(flatval2)
    setSearchFlat('')
  }
  function onSearchFlatId(val) {
    setSearchFlat(val)
  }

  useEffect(() => {
    if (searchAreaName != '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: searchAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        // console.log(res.data.activeType, 'res.data.activeType')
        setOptions(res.data.activeAreaList)
      })
    }
  }, [searchAreaName])

  useEffect(() => {
    if (login.data.user.role !== 'SuperAdmin' && areaType != '') {
      const getBuildingListReq = {
        areaType,
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      return dispatch(getBuildingList(getBuildingListReq))
    }
  }, [areaType])

  useEffect(() => {
    if (areaType === 'Building' && buildingId) {
      const getUnitFlatListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaType: 'Flat',
      }
      return dispatch(getUnitFlatList(getUnitFlatListReq))
    }
  }, [buildingId])

  useEffect(() => {
    if (areaType === 'Building' && buildingId && searchFlat != '') {
      const userData = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaId: buildingId,
        areaType: 'Flat',
        unit: searchFlat,
      }
      axios.post('society/getUnitList', userData).then((res) => {
        // if (res.status === 200 && res.data.activeUnitList != '') {
        setOptionsUnit(res.data.activeUnitList)
        // }
      })
    }
  }, [searchFlat])

  useEffect(() => {
    if (searchAreaName === '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        // areaName: searchAreaName,
      }
      axios.post('society/getAreaList', userData).then((res) => {
        setOptions2(res.data.areaListActive)
      })
    }
  }, [])

  useEffect(() => {
    if (allSecurityAlertList.success) {
      if (guardName === "") {
        setGuardName('')
        setGuardId('')
      }
    }
    if (allSecurityAlertList.failed) {
      if (guardName === "") {
        setGuardName('')
        setGuardId('')
      }
    }
    return () => {
      dispatch(resetGetUnitFlatList())
    }
  }, [])

  return (
    <>
      <Row>
        <Col md="3" sm="12">
          <FormGroup>
            <Label for="city">Building Name</Label>
            <Select
              type="select"
              value={bldgName}
              showSearch
              placeholder="Select Building Name"
              optionFilterProp="children"
              onChange={onChangeSearch}
              onSearch={onSearch}
              style={{ width: '100%', borderRadius: '25px' }}
            // loading={buildingNames.isLoading}
            >
              <Option className="loginSelect" value="">
                Select Building Name
              </Option>

              {searchAreaName != '' &&
                options != undefined &&
                options.map((d, id) => (
                  <Option
                    key={id}
                    className="loginSelect"
                    value={`${d.areaId},${d.areaName}`}
                  >
                    {d.areaName}
                  </Option>
                ))}
              {searchAreaName === '' &&
                options2.map((d, id) => (
                  <Option
                    key={id}
                    className="loginSelect"
                    value={`${d.areaId},${d.areaName}`}
                  >
                    {d.areaName}
                  </Option>
                ))}
            </Select>
          </FormGroup>
        </Col>
        {areaType === 'Building' && (
          <Col md="3" sm="12">
            <FormGroup>
              <Label for="flatid" lat>
                Flat
              </Label>
              <Select
                type="select"
                showSearch
                value={flatName}
                placeholder="Select Flat Number"
                optionFilterProp="children"
                onChange={onChangeSearchFlatId}
                onSearch={onSearchFlatId}
                style={{ width: '100%' }}
              >
                <Option className="loginSelect" value="">
                  {getFlat.data.message === 'Not Found' ? 'No Flat Found ' : "Select Flat Number"}
                </Option>
                {searchFlat != '' && optionsUnit != undefined
                  ? optionsUnit.map((d, id) => (
                    <Option
                      key={id}
                      className="loginSelect"
                      value={`${d.id},${d.unit}`}
                    >
                      {d.unit}
                    </Option>
                  ))
                  : getFlat.success &&
                  getFlat.data.activeUnitList.map(
                    (d, id) => (
                      <Option
                        key={id}
                        className="loginSelect"
                        value={`${d.id},${d.unit}`}
                      >
                        {d.unit}
                      </Option>
                    )
                  )}
              </Select>
            </FormGroup>
          </Col>
        )}

        {/* <Col md="6" sm="12" lg="4" xs="12">
          <Label for="flatNumber">Flat Number</Label>
          <Input
            type="text"
            name="flatNo"
            id="flatNo"
            placeholder="Flat Number"
            value={flatNo}
            onChange={handleChange}
            required
          />
        </Col> */}
        <Col md="3" sm="12" lg="3" xs="12">
          <Label for="guardName">Guard Name</Label>
          <Input
            type="text"
            name="guardName"
            id="guardName"
            placeholder="Guard Name"
            value={guardName}
            onChange={handleChange}
            required
          />
        </Col>
        <Col xs="12" sm="12" md="2">
          <Label>Date</Label>
          <Input
            name="dateFilter"
            value={dateFilter}
            type="date"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs="12"
          sm="12"
          md="12"
          style={{ textAlign: 'center', margin: '20px' }}
        >
          <Button
            className="cust-button"
            onClick={handleSubmit}
          // disabled={transactionList.isLoading || disabledBtn()}
          >
            {allSecurityAlertList.isLoading ? <Spin /> : 'Search'}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default SearchSecurityAlert
