import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// react plugin used to create charts
import { Line, Bar } from 'react-chartjs-2'
import { connect } from 'react-redux'
import UploadDocument from 'views/Documents/UploadDocument'
import { withRouter } from 'react-router'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Button,
  Label,
  FormGroup,
  Input,
  UncontrolledTooltip,
  UncontrolledCollapse,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
// core components
import PanelHeader from 'components/PanelHeader/PanelHeader.js'

import {
  dashboardPanelChart,
  dashboardShippedProductsChart,
  dashboardAllProductsChart,
  dashboard24HoursPerformanceChart,
} from 'variables/charts.js'
import Mumbai from '../../assets/img/mumbai.png'
import Login from 'views/Login'
import {
  resetgetSocietyDoc,
  getSocietyDoc,
} from 'redux/actions/getSocietyDocAction'
import VerifyUser from 'views/Documents/VerifyUser'
import LinkSociety from 'views/Documents/LinkSociety'
//members list
import { Alert, Popconfirm, Tooltip, Spin } from 'antd'
import NotificationPopups from 'components/NotificationPopups/NotificationPopups'
import {
  resetgetPendingMemberList,
  getPendingMemberList,
} from 'redux/actions/getPendingMemberListAction'
import {
  addUserStatus,
  resetaddUserStatus,
} from 'redux/actions/addUserStatusAction'
import DisplayAttachment from '../../components/Custom/DisplayAttachment'
import Util from 'validations/index'
import {
  applicationMessage,
  resetapplicationMessage,
} from 'redux/actions/applicationMessageAction'
import {
  resetUpdateUnitDocumentStatus,
  updateUnitDocumentStatus,
} from 'redux/actions/updateUnitDocumentStatusAction'
import {
  resetUpdateOwnerStatus,
  updateOwnerStatus,
} from 'redux/actions/updateOwnerStatusAction'

//amenities
import {
  addAmenityStatus,
  resetAddAmenityStatus,
} from 'redux/actions/addAmenityStatusAction'
import {
  addAmenityMessage,
  getAmenityMessage,
  resetAddAmenityMessage,
  resetGetAmenityMessage,
} from 'redux/actions/amenityMessageAction'
import {
  getAmenityList,
  resetgetAmenityList,
} from 'redux/actions/getAmenityListAction'
import countryData from 'assets/Json/data'

function SuperAdminDashBoard() {
  const [openDoc, setopenDoc] = useState(false)
  const [userStatus, setUserStatus] = useState(false)
  const [verifyOtp, setVerifyOtp] = useState(false)
  const [linkSociety, setLinkSociety] = useState(false)
  const login = useSelector(({ login }) => login)
  // const getSocietyDocs = useSelector(({ getSocietyDocs }) => getSocietyDocs)
  const dispatch = useDispatch()
  // console.log('login status', Object.entries(login.data.society).length)

  //Member list
  const [userMessage, setuserMessage] = useState('')
  const [userId, setUserId] = useState('')
  const [docId, setdocId] = useState('')
  const [docRemark, setdocRemark] = useState('')
  const [data, setData] = useState('')
  const [modalDoc, setModalDoc] = useState(false)
  const [showMsgField, setshowMsgField] = useState(false)
  const [rejectRemark, setRejectRemark] = useState('')
  // const [showMsgField, setshowMsgField] = useState(false)
  const [showRejectMsg, setShowRejectMsg] = useState(false)
  const [fileModal, setFileModal] = useState(false)
  const [attachment, setAttachment] = useState(false)
  const [limit] = useState(5)
  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState('')
  const getPendingMembers = useSelector(
    ({ getPendingMemberList }) => getPendingMemberList
  )
  const applicationMsg = useSelector(
    ({ applicationMessage }) => applicationMessage
  )

  const addedUserStatus = useSelector(({ addUserStatus }) => addUserStatus)
  const updateUnitDocStatus = useSelector(
    ({ updateUnitDocumentStatus }) => updateUnitDocumentStatus
  )
  const ownerStatus = useSelector(({ updateOwnerStatus }) => updateOwnerStatus)
  const memberList = useSelector(
    ({ getPendingMemberList }) => getPendingMemberList
  )

  // const lPage = totalItems / limit
  // setLastPage(lPage)
  // console.log(Math.ceil(totalItems / limit))

  useEffect(() => {
    getPendingMemberListRequest()
  }, [])
  console.log(countryData, 'Country data')

  const getPendingMemberListRequest = () => {
    if (login.data.user.role != 'SuperAdmin') {
      const getPendingMemberListReq = {
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        userStatus: 'pending',
        limit,
        page,
      }
      return dispatch(getPendingMemberList(getPendingMemberListReq))
    }
  }

  useEffect(() => {
    getPendingMemberListRequest()
  }, [page])

  useEffect(() => {
    setPage(1)
    // console.log(page)
  }, [memberList.failed])
  const handleMemberStatus = (applicationId, status) => {
    // console.log(status)
    if (login.data.user.role != 'SuperAdmin') setUserId(applicationId)
    if (status === 'approved') {
      const addUserStatusReq = {
        id: applicationId,
        userStatus: status,
        initiatedBy: login.data.user.role,
      }
      return dispatch(updateOwnerStatus(addUserStatusReq))
    }
    if (status === 'rejected') {
      setShowRejectMsg(true)
    }
  }

  const addUserStatusRequest = (id, status) => {
    if (login.data.user.role != 'SuperAdmin') {
      const addUserStatusReq = {
        id,
        userStatus: status,
        initiatedBy: login.data.user.role,
        message: rejectRemark,
      }
      return dispatch(updateOwnerStatus(addUserStatusReq))
    }
  }

  const handleRemark = ({ target: { name, value } }) => {
    if (name === 'docRemark') setdocRemark(value)
    if (name === 'userMessage') setuserMessage(value)
    if (name === 'rejectRemark') setRejectRemark(value)
  }

  const handleDocStatus = (id, status) => {
    setdocId(id)
    setdocRemark('')
    if (status === 'rejected') {
      setshowMsgField(true)
      // updateDocumentStatusRequest(id, status)
    } else {
      setshowMsgField(false)
      updateDocumentStatusRequest(id, status)
    }
  }
  const handleDocModal = (data) => {
    // console.log(data)
    setData(data)
    setModalDoc(true)
  }

  const toggle = () => {
    setModalDoc(!modalDoc)
  }

  const handleRejectDoc = (e, id, status) => {
    e.preventDefault()
    updateDocumentStatusRequest(id, status)
  }

  const updateDocumentStatusRequest = (docId, docStatus) => {
    if (login.data.user.role != 'SuperAdmin') {
      const updateDocStatusReq = {
        initiatedBy: login.data.user.role,
        id: docId,
        status: docStatus,
      }

      const updateRemark = {
        remark: docRemark,
      }

      let updateReq = ''

      if (docStatus === 'approved') {
        updateReq = { ...updateDocStatusReq }
        return dispatch(updateUnitDocumentStatus(updateReq))
      }
      if (docStatus === 'rejected' && docRemark != '') {
        updateReq = { ...updateDocStatusReq, ...updateRemark }
        return dispatch(updateUnitDocumentStatus(updateReq))
      }
    }

    // console.log('updateDocStatusReq', updateReq)
  }

  useEffect(() => {
    if (ownerStatus.success) {
      setRejectRemark('')
      setdocRemark('')
      getPendingMemberListRequest()
      NotificationPopups('success', 'Success', ownerStatus.data.message)
    }
    if (ownerStatus.failed) {
      NotificationPopups('error', 'Failed', ownerStatus.data.message)
    }
    if (ownerStatus.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetUpdateOwnerStatus())
    }
  }, [ownerStatus.success, ownerStatus.failed, ownerStatus.network])

  useEffect(() => {
    if (updateUnitDocStatus.success) {
      getPendingMemberListRequest()
      NotificationPopups('success', 'Success', updateUnitDocStatus.data.message)
    }
    if (updateUnitDocStatus.failed) {
      NotificationPopups('error', 'Failed', updateUnitDocStatus.data.message)
    }
    if (updateUnitDocStatus.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetUpdateUnitDocumentStatus())
    }
  }, [
    updateUnitDocStatus.success,
    updateUnitDocStatus.failed,
    updateUnitDocStatus.network,
  ])

  //Open Files
  const handleOpenFile = (a) => {
    setAttachment(a)
    setFileModal(true)
  }

  const toggleFileModal = () => {
    setFileModal(!fileModal)
  }

  useEffect(() => {
    if (
      login.data.adminStatus &&
      (login.data.adminStatus === 'document' ||
        login.data.adminStatus === 'pending' ||
        login.data.adminStatus === 'rejected') &&
      login.data.user.role === 'Society'
    ) {
      setopenDoc(true)
      // console.log(login.data.adminStatus)
    }
    if (login.data.user.userStatus) {
      setUserStatus(login.data.user.userStatus)
    }
    if (
      login.data.user.role === 'Society' &&
      login.data.user.userStatus === 'created'
    ) {
      setVerifyOtp(true)
    }
    // if (login.success && login.isAvailable != true && login.isSociety != true) {
    //   sessionStorage.removeItem('persist:root')
    //   window.location.href = '/'
    // }
  }, [login.success])

  //Amenities
  const [message, setMessage] = useState({})
  const amenityList = useSelector(({ getAmenityList }) => getAmenityList)
  const addedAmenityStatus = useSelector(
    ({ addAmenityStatus }) => addAmenityStatus
  )
  const getAmenityMessageData = useSelector(
    ({ getAmenityMessage }) => getAmenityMessage
  )
  const addAmenityMessageData = useSelector(
    ({ addAmenityMessage }) => addAmenityMessage
  )
  const getAmenityListRequest = (status) => {
    if (login.data.user.role != 'SuperAdmin') {
      const getAmenityListReq = {
        initiatedBy: login.data.user.role,
        amenityStatus: status,
        // userId: login.data.user.id,
        societyId: login.data.society.id,
        page,
        limit,
      }
      return dispatch(getAmenityList(getAmenityListReq))
    }
  }

  useEffect(() => {
    getAmenityListRequest()
  }, [])

  const handleAmenityStatus = (id, status) => {
    const amenityStatusReq = {
      amenityId: id,
      status: status,
    }
    return dispatch(addAmenityStatus(amenityStatusReq))
  }

  useEffect(() => {
    if (addedAmenityStatus.success) {
      getAmenityList('pending')
      NotificationPopups('success', 'Success', addedAmenityStatus.data.message)
    }
    if (addedAmenityStatus.failed) {
      NotificationPopups('error', 'Failed', addedAmenityStatus.data.message)
    }
    if (addedAmenityStatus.network) {
      NotificationPopups(
        'error',
        'Network Error',
        'Check your internet connection'
      )
    }
    return () => {
      dispatch(resetAddAmenityStatus())
    }
  }, [
    addedAmenityStatus.success,
    addedAmenityStatus.failed,
    addedAmenityStatus.network,
  ])

  // useEffect(() => {
  //   console.log('get amenity message data = ', getAmenityMessageData)
  // }, [getAmenityMessageData])

  const handleAmenityMessage = (e, id) => {
    message[id] = {
      content: e.target.value,
    }
    console.log('message in content = ', message)
    setMessage(message)
  }

  const handleAddAmenityMessage = (id) => {
    if (login.data.user.role != 'SuperAdmin') {
      const req = {
        amenityRecord: {
          amenityId: id,
          adminId: login.id,
          role: 'Society',
          content: message[id].content ? message[id].content : '',
          attachment: message[id].attachment ? message[id].attachment : '',
        },
      }

      console.log('request = ', req)
      dispatch(addAmenityMessage(req))
    }
  }

  const getAmenityMessageList = (id) => {
    const req = {
      amenityId: id,
    }
    dispatch(getAmenityMessage(req))
  }

  useEffect(() => {
    if (addAmenityMessageData.success) {
      message[addAmenityMessageData.data.amenityId] = {
        content: '',
      }
      setMessage(message)
    }
    return () => {
      dispatch(resetAddAmenityMessage())
    }
  }, [
    addAmenityMessageData.success,
    addAmenityMessageData.failed,
    addAmenityMessageData.network,
  ])

  return (
    <>
      <PanelHeader size="lg" />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Visiting Help</h5>
                <CardTitle tag="h4">24 Hours Entries</CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={dashboard24HoursPerformanceChart.data}
                    options={dashboard24HoursPerformanceChart.options}
                  />
                </div>
              </CardBody>
              <CardFooter>
                <div className="stats">
                  <i className="now-ui-icons ui-2_time-alarm" /> Last 7 days
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Card>
              <CardHeader>
                <h5 className="card-category">Vistiors List</h5>
                <CardTitle tag="h4">Top visitors </CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Company</th>
                      {/* <th6 className="text-right">Salary</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Dakota Rice</td>
                      <td>Delivery</td>
                      <td>Amazon</td>
                      {/* <td className="text-right">$36,738</td> */}
                    </tr>
                    <tr>
                      <td>Minerva Hooper</td>
                      <td>Cab</td>
                      <td>UBER</td>
                      {/* <td className="text-right">$23,789</td> */}
                    </tr>
                    <tr>
                      <td>Sage Rodriguez</td>
                      <td>Guest</td>
                      <td>-</td>
                      {/* <td className="text-right">$56,142</td> */}
                    </tr>
                    <tr>
                      <td>Doris Greene</td>
                      <td>Daily Help</td>
                      <td>-</td>
                      {/* <td className="text-right">$63,542</td> */}
                    </tr>
                    <tr>
                      <td>Mason Porter</td>
                      <td>Visiting Help</td>
                      <td>Doctor</td>
                      {/* <td className="text-right">$78,615</td> */}
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          {/* complaints  */}
          <Col xs={12} md={6}>
            <Card>
              <CardHeader>
                <h5 className="card-category">Complaints</h5>
                <CardTitle tag="h4">Recent Complaints </CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Company</th>
                      {/* <th className="text-right">Salary</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Dakota Rice</td>
                      <td>Delivery</td>
                      <td>Amazon</td>
                      {/* <td className="text-right">$36,738</td> */}
                    </tr>
                    <tr>
                      <td>Minerva Hooper</td>
                      <td>Cab</td>
                      <td>UBER</td>
                      {/* <td className="text-right">$23,789</td> */}
                    </tr>
                    <tr>
                      <td>Sage Rodriguez</td>
                      <td>Guest</td>
                      <td>-</td>
                      {/* <td className="text-right">$56,142</td> */}
                    </tr>
                    <tr>
                      <td>Doris Greene</td>
                      <td>Daily Help</td>
                      <td>-</td>
                      {/* <td className="text-right">$63,542</td> */}
                    </tr>
                    <tr>
                      <td>Mason Porter</td>
                      <td>Visiting Help</td>
                      <td>Doctor</td>
                      {/* <td className="text-right">$78,615</td> */}
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}
export default SuperAdminDashBoard
