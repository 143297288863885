import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  Table,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  UncontrolledCollapse,
} from 'reactstrap'
import { Divider, Tooltip } from 'antd'
import Car from '../../assets/img/Icons/Visitors/car3.svg'

const ParkingSlotDetails = () => {

  const parkingSpaces = [
    { id: 'A3', occupied: false },
    { id: 'A6', occupied: true },
    { id: 'A8', occupied: false },
    { id: 'B6', occupied: true },
    { id: 'B10', occupied: false },
    { id: 'C3', occupied: false },
    { id: 'D5', occupied: true },
    { id: 'E3', occupied: false },
    { id: 'F5', occupied: true },
    { id: 'G3', occupied: false },
    // Add more spaces as needed
  ];

  const svg = <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="70%" height="70%" viewBox="0 0 225.000000 225.000000" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#4e97e6" stroke="none">
      <path d="M980 2229 c-102 -17 -209 -61 -237 -98 -29 -38 -81 -150 -92 -196 -15 -69 -18 -174 -8 -341 l9 -141 -47 -27 c-50 -30 -76 -69 -58 -87 16 -16 90 25 97 53 3 13 3 -238 1 -557 -4 -648 -2 -688 50 -739 17 -18 55 -39 85 -48 148 -47 466 -50 665 -6 133 29 169 142 156 493 -12 331 -13 875 -1 875 5 0 10 -11 10 -25 0 -19 10 -30 41 -46 29 -14 45 -17 52 -10 16 16 -8 59 -44 79 -76 42 -73 31 -59 198 11 122 11 165 1 230 -15 92 -60 222 -97 277 -62 94 -315 150 -524 116z m157 -44 c7 -20 -2 -29 -21 -22 -9 4 -13 13 -10 22 8 19 23 19 31 0z m-287 -55 c0 -21 -8 -35 -26 -47 -27 -17 -74 -108 -74 -142 0 -19 -36 -71 -49 -71 -9 0 18 122 44 201 12 36 26 56 49 70 43 26 56 24 56 -11z m601 14 c17 -8 37 -30 44 -49 20 -46 64 -213 58 -219 -12 -11 -41 37 -62 101 -14 45 -34 81 -52 99 -19 18 -29 38 -29 56 0 33 1 33 41 12z m-140 -384 c104 -35 198 -116 216 -188 7 -29 -16 -100 -88 -279 -12 -30 -15 -31 -58 -26 l-46 5 -1 -213 c0 -118 -3 -229 -7 -246 -6 -32 -5 -33 27 -33 18 0 39 -7 46 -14 7 -9 14 -63 17 -130 l6 -115 -49 -135 c-27 -75 -55 -138 -63 -141 -7 -3 -55 -11 -106 -17 -95 -12 -221 -2 -264 22 -12 6 -36 59 -65 141 l-46 132 11 111 c13 125 24 146 76 146 30 0 34 3 28 20 -4 11 -7 122 -7 246 l0 226 -56 -4 -56 -3 -53 130 c-29 71 -53 144 -53 161 0 56 83 149 168 186 115 51 301 59 423 18z m-537 -445 c43 -122 56 -185 56 -279 0 -113 -33 -361 -50 -371 -12 -7 -49 152 -65 280 -15 122 -21 516 -8 529 7 7 9 4 67 -159z m776 -63 c0 -244 -9 -336 -47 -497 -26 -107 -27 -109 -39 -79 -21 52 -47 310 -40 404 4 66 17 118 58 237 29 84 56 153 60 153 4 0 8 -98 8 -218z m-660 -1028 l45 -47 -35 7 c-19 4 -52 9 -73 13 -47 7 -58 30 -28 55 33 26 42 23 91 -28z m570 16 c6 -11 8 -24 6 -29 -6 -10 -136 -35 -142 -28 -3 2 14 21 36 41 45 41 83 47 100 16z" />
      <path d="M1053 1073 c4 -120 7 -235 7 -255 l0 -38 74 0 74 0 4 58 c3 31 5 125 6 207 2 83 5 171 9 198 l6 47 -93 0 -93 0 6 -217z" />
    </g>
  </svg>

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Col>
              <div className="typeHeading" id="onTop">Visitor Parking Slots</div>
            </Col>

            {/* <div className='parkingLayout'>
              <span>Building</span>
              <Row>
                <Divider style={{ backgroundColor: "black" }} />
                <Col md='4'>
                  <span>A1</span>
                  <span>A2</span>
                  <br />
                  <span>A1</span>
                  <span>A2</span>
                </Col>
                <Col md='4'>
                  <div>A1</div>
                  <div>A2</div>
                </Col>
                <Col md='4'>
                  <div>A1</div>
                  <div>A2</div>
                </Col>
              </Row>
            </div> */}
            <div className="parking-lot-container">
              <div className="parking-header">
                <h5>Hill Tower</h5>
                <div className="legend">
                  <span className="occupied-box"></span> Occupied
                  <span className="available-box"></span> Available
                </div>
              </div>
              <div className="parking-lot">
                {parkingSpaces.map((space) => (
                  <div
                    key={space.id}
                    className={`parking-space ${space.occupied ? 'occupied' : 'available'}`}
                  >
                    {space.occupied ? <Tooltip style={{ background: 'blue' }} placement="right" title={space.id}>{svg}</Tooltip> : space.id}
                  </div>
                ))}
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ParkingSlotDetails