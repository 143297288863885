import Dashboard from 'views/Dashboard/SocietyDashboard/index.jsx'
import Login from 'views/Login'
import Notifications from 'views/Notifications.js'
import Icons from 'views/Icons.js'
import Typography from 'views/Typography.js'
import TableList from 'views/TableList.js'
import Maps from 'views/Maps.js'
import Upgrade from 'views/Upgrade.js'
import UserPage from 'views/Profile/UserPage.js'
import Society from 'views/Society/Society'
import Amenity from 'views/Amenity/Amenity'
import Complaints from 'views/Complaints/Complaints'
import Admin from 'views/Admin/Admin'
import SocietyDetail from 'views/SocietyDetails/SocietyDetail.js'
import NoticeBoard from 'views/NoticeBoard/NoticeBoard.js'
import Members from 'views/Members/Members'
import UploadDocument from 'views/Documents/UploadDocument'
import EmergencyNumber from 'views/EmergencyNumber/EmergencyNumber'
import Categories from 'views/Categories/Categories'
import Configuration from 'views/Configuration/Configuration'
import ChangePassword from 'views/Password/ChangePassword'
import ServiceProvider from 'views/Services/ServiceProvider'
import RegisterSociety from 'views/Account/RegisterSociety'
import DashboardSA from 'views/SuperAdminDashboard/DashboardSA'
import SuperAdminDashBoard from 'views/Dashboard/SuperAdminDashBoard'
import DashboardConfiguration from 'views/DashboardConfiguration/DashboardConfig'
import Visitor from 'views/Visitor/Index'
import ProductAndServices from 'views/ProductAndServices/index'
import FeeConfig from 'views/FeeConfig'
import Maintenance from 'views/Maintenance'
import MaintenanceBilling from 'views/MaintenanceBilling'
import Transactions from 'views/Transactions'
// import CategoriesSA from 'views/SuperAdminCategories/CategoriesSa'
import GatewayManagement from 'views/GatewayManagement'
import TransactionDetails from 'views/TransactionDetails'
import SecurityAlert from 'views/SecurityAlert'
import ErrorPage from 'views/ErrorPage/ErrorPage'
import Booking from './views/Bookings/Booking'

var dashRoutes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'design_app',
    component: Dashboard,
    layout: '/Society',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'design_app',
    component: SuperAdminDashBoard,
    layout: '/Superadmin',
  },
  {
    path: '/society-detail',
    name: 'Society Detail',
    icon: 'business_bank',
    component: SocietyDetail,
    layout: '/Society',
  },
  {
    path: '/society-list',
    name: 'Society List',
    icon: 'business_bank',
    component: Society,
    layout: '/Superadmin',
  },
  {
    path: '/notice-board',
    name: 'Notice Board',
    icon: 'ui-1_calendar-60',
    component: NoticeBoard,
    layout: '/Society',
  },
  {
    path: '/members',
    name: 'Members',
    icon: 'users_single-02',
    component: Members,
    layout: '/Society',
  },
  {
    path: '/society-users',
    name: 'Society Users',
    icon: 'users_single-02',
    component: Admin,
    layout: '/Society',
  },
  {
    path: '/complaints',
    name: 'Complaints',
    icon: 'travel_info',
    component: Complaints,
    layout: '/Society',
  },
  {
    path: '/amenity-booking',
    name: 'Amenity Booking',
    icon: 'shopping_shop',
    component: Amenity,
    layout: '/Society',
  },
  {
    path: '/visitor-parking-booking',
    name: 'Visitor Parking Booking',
    icon: 'shopping_shop',
    component: Booking,
    layout: '/Society',
  },
  {
    path: '/emergency',
    name: 'Emergency Contacts',
    icon: 'sport_user-run',
    component: EmergencyNumber,
    layout: '/Society',
  },
  /*{
    path: "/_company",
    name: "Company",
    icon: "users_single-02",
    component: EmergencyNumber,
    layout: "/admin",
  },*/
  {
    path: '/categories',
    name: 'Categories',
    icon: 'loader_gear',
    component: Categories,
    layout: '/Society',
  },
  {
    path: '/categories',
    name: 'Categories',
    icon: 'loader_gear',
    component: Categories,
    layout: '/Superadmin',
  },
  // {
  //   path: '/categories',
  //   name: 'Categories',
  //   icon: 'loader_gear',
  //   component: CategoriesSA,
  //   layout: '/Superadmin',
  // },
  {
    path: '/configuration',
    name: 'Configuration',
    icon: 'gestures_tap-01',
    component: Configuration,
    layout: '/Society',
  },
  {
    path: '/configuration',
    name: 'Configuration',
    icon: 'gestures_tap-01',
    component: Configuration,
    layout: '/Superadmin',
  },
  /*{
    path: "/upload-document",
    name: "Upload Documents",
    icon: "arrows-1_cloud-upload-94",
    component: UploadDocument,
    layout: "/admin",
  },*/
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "design_image",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "location_map-big",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "ui-1_bell-53",
  //   component: Notifications,
  //   layout: "/admin",
  // },
  {
    path: '/user-page',
    name: 'User Profile',
    icon: 'users_single-02',
    component: UserPage,
    layout: '/Society',
  },
  // {
  //   path: "/extended-tables",
  //   name: "Table List",
  //   icon: "files_paper",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "design-2_ruler-pencil",
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   pro: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "objects_spaceship",
  //   component: Upgrade,
  //   layout: "/admin",
  // },
  {
    path: '/changePassword',
    name: 'Change Password',
    icon: 'design_app',
    component: ChangePassword,
    layout: '/Society',
  },
  {
    path: '/changePassword',
    name: 'Change Password',
    icon: 'design_app',
    component: ChangePassword,
    layout: '/Superadmin',
  },
  {
    path: '/serviceType',
    name: 'Service Type',
    icon: 'loader_gear',
    component: ServiceProvider,
    layout: '/Superadmin',
  },
  {
    path: '/society-users',
    name: 'Society Users',
    icon: 'loader_gear',
    component: Admin,
    layout: '/Superadmin',
  },

  // {
  //   path: '/linkSociety',
  //   name: 'Link Society',
  //   component: RegisterSociety,
  //   layout: '/admin',
  // },
  {
    path: '/dashboardConfiguration',
    name: 'Dashboard config',
    icon: 'design_app',
    component: DashboardConfiguration,
    layout: '/Superadmin',
  },
  {
    path: '/dashboardConfiguration',
    name: 'Dashboard config',
    icon: 'design_app',
    component: DashboardConfiguration,
    layout: '/Society',
  },
  {
    path: '/Visitor',
    name: 'Visitor',
    icon: '',
    component: Visitor,
    layout: '/Society',
  },
  {
    path: '/ProductAndServices',
    name: 'Product And Services',
    icon: '',
    component: ProductAndServices,
    layout: '/Superadmin',
  },
  {
    path: '/FeeConfiguration',
    // path: '/MaintenanceMaster',
    name: 'Fee Configuartion',
    icon: '',
    component: FeeConfig,
    layout: '/Superadmin',
  },
  {
    path: '/MaintenanceMapping',
    name: 'Maintenance Mapping',
    icon: '',
    component: Maintenance,
    layout: '/Society',
  },
  {
    path: '/MaintenanceBilling',
    name: 'Maintenance Billing',
    icon: '',
    component: MaintenanceBilling,
    layout: '/Society',
  },
  {
    path: '/TransactionMaster',
    name: 'Transaction Master',
    icon: '',
    component: Transactions,
    layout: '/Society',
  },
  {
    path: '/GatewayManagement',
    name: 'Gateway Management',
    icon: '',
    component: GatewayManagement,
    layout: '/Superadmin',
  },
  {
    path: '/TransactionDetails',
    name: 'Transaction Details',
    icon: '',
    component: TransactionDetails,
    layout: '/Superadmin',
  },
  {
    path: '/SecurityAlert',
    name: 'Security Alert',
    icon: '',
    component: SecurityAlert,
    layout: '/Society',
  },
  // {
  //   path: '/SessionOut',
  //   name: 'Session Out',
  //   icon: '',
  //   component: ErrorPage,
  //   layout: '/Society',
  // },
  // {
  //   path: '/SessionOut',
  //   name: 'Session Out',
  //   icon: '',
  //   component: ErrorPage,
  //   layout: '/Superadmin',
  // },
]
export default dashRoutes
