import React, { useEffect, useState } from 'react'
import moment from 'moment';
import './demo.css'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from 'reactstrap'

import Util from 'validations'
import { Spin, Select } from 'antd'
import { useSelector } from 'react-redux';
import axios from 'config/axios'

const ParkingSlots = () => {

  // const [currentDate, setCurrentDate] = useState(moment());

  // const prevDay = () => {
  //   setCurrentDate(currentDate.clone().subtract(1, 'days'));
  // };

  // const nextDay = () => {
  //   setCurrentDate(currentDate.clone().add(1, 'days'));
  // };

  const [date, setDate] = useState('')
  const [building, setBuilding] = useState('')
  const [buildingId, setBuildingId] = useState('')
  const [time, setTime] = useState('')
  const [options, setOptions] = useState([])
  const [options2, setOptions2] = useState([])
  const [serachAreaName, setSearchAreaName] = useState('')

  const login = useSelector(({ login }) => login)

  const handleChange = ({ target: name, value }) => {
    console.log(value,"dsvsdb")
    if (name === "date") {
      setDate(value)
    }
  }

  const timeIntervals = [
    "12:00 AM-01:00 AM",
    "01:00 AM-02:00 AM",
    "02:00 AM-03:00 AM",
    "03:00 AM-04:00 AM",
    "04:00 AM-05:00 AM",
    "05:00 AM-06:00 AM",
    "06:00 AM-07:00 AM",
    "07:00 AM-08:00 AM",
    "08:00 AM-09:00 AM",
    "09:00 AM-10:00 AM",
    "10:00 AM-11:00 AM",
    "11:00 AM-12:00 PM"
  ];

  const { Option } = Select

  useEffect(() => {
    if (serachAreaName != '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
        areaName: serachAreaName,
      }
      axios.post('society/getAreaSearchList', userData).then((res) => {
        setOptions(res.data.activeAreaList)
      })
    }
  }, [serachAreaName])

  useEffect(() => {
    if (serachAreaName === '') {
      const userData = {
        areaType: 'building',
        initiatedBy: login.data.user.role,
        societyId: login.data.society.id,
      }
      axios.post('society/getAreaList', userData).then((res) => {
        setOptions2(res.data.areaListActive)
      })
    }
  }, [])

  function onChangeSearch(value) {
    let val = value.split(',')
    let val1 = val[0]
    let val2 = val[1]
    setBuildingId(val1)
    setBuilding(val2)
    setSearchAreaName('')
  }

  function onSearch(value) {
    setSearchAreaName(value)
  }

  function onChangeSearchTime(value) {
    setTime(value)
  }

  // function onSearchTime(value) {
  //   // setSearchAreaName(value)
  // }

  return (
    // <div>
    //     <div className="calendar-container">
    //     <div className="nav">
    //       <button onClick={prevDay} className="arrow">&lt;</button>
    //       <div className="dates">
    //         {[-1, 0, 1, 2, 3].map((offset) => {
    //           const day = currentDate.clone().add(offset, 'days');
    //           return (
    //             <div
    //               key={offset}
    //               className={`day ${offset === 0 ? 'selected' : ''}`}
    //             >
    //               <div className="day-name">{day.format('ddd')}</div>
    //               <div className="day-number">{day.format('DD')}</div>
    //               <div className="month-name">{day.format('MMM')}</div>
    //             </div>
    //           );
    //         })}
    //       </div>
    //       <button onClick={nextDay} className="arrow">&gt;</button>
    //     </div>
    //   </div>
    // </div>
    <div style={{ marginTop: '50px' }}>
      <Row>
        <Col xs="12" sm="12" md="3">
          <Label>Date</Label>
          <Input
            name="dateFilter"
            value={date}
            type="date"
            onChange={handleChange}
          />
        </Col>
        <Col md="3" sm="12">
          <FormGroup>
            <Label for="city">Building Name</Label>
            <Select
              type="select"
              value={building}
              showSearch
              placeholder="Select Building Name"
              optionFilterProp="children"
              onChange={onChangeSearch}
              onSearch={onSearch}
              style={{ width: '100%', borderRadius: '25px' }}
            >
              <Option className="loginSelect" value="">
                Select Building Name
              </Option>

              {serachAreaName != '' &&
                options != undefined &&
                options.map((d, id) => (
                  <Option
                    key={id}
                    className="loginSelect"
                    value={`${d.areaId},${d.areaName}`}
                  >
                    {d.areaName}
                  </Option>
                ))}
              {serachAreaName === '' &&
                options2.map((d, id) => (
                  <Option
                    key={id}
                    className="loginSelect"
                    value={`${d.areaId},${d.areaName}`}
                  >
                    {d.areaName}
                  </Option>
                ))}
            </Select>
          </FormGroup>
        </Col>
        <Col md="3" sm="12">
          <FormGroup>
            <Label for="city">Time</Label>
            <Select
              type="select"
              value={time}
              showSearch
              placeholder="Select Time"
              optionFilterProp="children"
              onChange={onChangeSearchTime}
              // onSearch={onSearchTime}
              style={{ width: '100%', borderRadius: '25px' }}
            >
              <Option className="loginSelect" value="">
                Select Time
              </Option>
              {timeIntervals.map((d, id) => (
                <Option
                  key={id}
                  className="loginSelect"
                  value={d}
                >
                  {d}
                </Option>
              ))
              }
            </Select>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col
          xs="12"
          sm="12"
          md="12"
          style={{ textAlign: 'center', margin: '20px' }}
        >
          <Button
            className="cust-button"
          // onClick={handleSubmit}
          >
            {/* {allSecurityAlertList.isLoading ? <Spin /> : 'Search'} */}
            Search
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default ParkingSlots