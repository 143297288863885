// import { Padding } from '@mui/icons-material'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

import { Table, } from 'antd'

import { Card } from 'antd'

import './demo.css'

const { Meta } = Card;

const dataSource = [
  {
    key: '1',
    date: '16 Nov 2024 12:00 pm',
    name: 'Abhi',
    type: "Parking",
    slotnumber: 'A1',
    carNumber: "MH 02 AC 1785",
    status: '40 minutes left',
  },
  {
    key: '2',
    date: '26 Aug 2024 05:00 pm',
    name: 'Bunny',
    type: "Booking",
    slotnumber: 'A3',
    carNumber: "MH 48 GB 7897",
    status: 'Complete',
  },
  {
    key: '3',
    date: '10 Set 2024 8:00 pm',
    name: 'Raj',
    type: 'Available',
    slotnumber: 'A8',
    carNumber: "MH 06 QW 4562",
    status: 'In Progress',
  },
];

const columns = [
  {
    title: 'Date-Time',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title:'Slot Number',
    dataIndex:'slotnumber',
    key: 'slotnumber'
  },
  {
    title: 'Car Registration Number',
    dataIndex: 'carNumber',
    key: 'carNumber',
  },
  {
    title: 'Staus',
    dataIndex: 'status',
    key: 'status',
  },
];

function ParkingStatus() {

  return (
    <div style={{ marginTop: '50px' }}>
      <Row style={{ justifyContent: "space-evenly" }}>
        <Card
          hoverable
          style={{
            width: 240,
            fontSize:'16px',
            background:'#ffecb3'
          }}
        >
          <Meta title={<span style={{fontSize:'30px'}}>200</span>} description="Total Parking Capacity" />
        </Card>
        <Card
          hoverable
          style={{
            width: 240,
            fontSize:'16px',
            background:'#c8e6c9'
          }}
        >
          <Meta title={<span style={{fontSize:'30px'}}>200</span>} description="Available Parking Space" />
        </Card>
        <Card
          hoverable
          style={{
            width: 240,
            fontSize:'16px',
            background:'#b3e5fc'
          }}
        >
          <Meta title={<span style={{fontSize:'30px'}}>200</span>} description="Booking Slots" />
        </Card>
      </Row>

      <Col>
        <div className="typeHeading" id="onTop" style={{ marginTop: '60px' }}>Booking</div>
      </Col>


      <Table dataSource={dataSource} columns={columns} />
    </div>
  )
}

export default ParkingStatus
